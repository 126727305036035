import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Form, Input, InputNumber, notification, Radio, Row, Select, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getEnumDictionary } from "src/utils/enumUtils";
import PremierPlusMachineType from "src/consts/MachineType";
import { useBinInfoContext } from "src/queries/BinInfoContext";
import { useGetPremierPlusBinDto } from "src/queries/useGetPremierPlusBinDto";
import ConfigureBasicSettingsRequestDTO from "src/models/ConfigureBasicSettingsRequestDTO";
import FanTypeId from "src/consts/FanTypeId";
import BinApiService from "src/api/BinApiService";
import { queryClient } from "src";

const { Title, Text } = Typography;

const SettingsPanel = () => {

    const [form] = Form.useForm<ConfigureBasicSettingsRequestDTO>();
    const values = Form.useWatch<ConfigureBasicSettingsRequestDTO>([], { form, preserve: true });
    const [loading, setLoading] = useState(false);
    const binInfoDto = useBinInfoContext();
    const {getPremierPlusBinDtoQuery, getPremierPlusBinDtoQueryKey } = useGetPremierPlusBinDto(binInfoDto.id, {enabled: true, refetchOnWindowFocus: false });

    useEffect( () => {

        if(getPremierPlusBinDtoQuery.data){
            const systemSettings = getPremierPlusBinDtoQuery.data?.systemSettings;
            if(systemSettings != null){
                form.setFieldValue("fanTypeId", systemSettings.fanTypeId);
                form.setFieldValue("machineType", systemSettings.machineType);
                form.setFieldValue("driFloMachineDelaySeconds", systemSettings.driFloMachineDelaySeconds);
                form.setFieldValue("augurSettings", systemSettings.augurSettings);
                form.setFieldValue("storageBinSettings", systemSettings.storageBinSettings);
            }
        }

    }, [getPremierPlusBinDtoQuery.dataUpdatedAt]);
    
    const fanItems = getEnumDictionary(FanTypeId).map(({ label, value }) => (
        <Select.Option key={value} value={value}>
            {label}
        </Select.Option>
    ));

    const onFinish = async (values: ConfigureBasicSettingsRequestDTO) => {

        try {
            setLoading(true);
            values?.storageBinSettings?.map((stb) => {
                if(stb?.transferAugurRotationString != null){
                    const rotation = stb?.transferAugurRotationString?.split(',').map(Number);
                    stb.transferAugurRotation = rotation;
                }
            });
            
            const result = await BinApiService.premierPlus_ModifyBasicSettings(binInfoDto.id, values);
            notification.success({ message: "Basic settings upload successful" });
            queryClient.invalidateQueries({queryKey: [getPremierPlusBinDtoQueryKey]});
            // setChosenDeviceID("");
            // setEnableSubmitButton(false);

        } catch (err) {
            notification.error({
                message: "Could modify basic settings",
                description: err?.errorDetails?.detail ?? "Contact support"
            });
        }finally{
            setLoading(false);
        }
    }

    return(
        <>
            <Title>System configuration</Title>
            <Text>
                In this page, you can configure all behaviors of the system.
            </Text>
            <Divider/>
            <Form
            form={form}
            onFinish={onFinish}
            >
                <Spin spinning={loading || getPremierPlusBinDtoQuery.isFetching}>
                    <Row >
                        <Col sm={24} xxl={12}>
                            <Title level={3}>Set augur names and cleanout delays</Title>
                            <Form.List name="augurSettings">
                                {() => (
                                    <>
                                        {getPremierPlusBinDtoQuery?.data?.augurs?.map((augur, index) => (
                                            <>
                                                <Row justify="center" align="middle" gutter={[16, 16]} key={index}>
                                                    <Form.Item name={[index,"number"]} hidden />
                                                    <Col>
                                                        <Form.Item
                                                        name={[index, "name"]}
                                                        label={`Augur ${index+1} name`}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col>
                                                        <Form.Item
                                                        name={[index, "cleanoutDelaySeconds"]}
                                                        label={`Augur ${index+1} cleanout delay`}
                                                        >
                                                            <InputNumber />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <br/>
                                            </>
                                        ))}
                                    </>
                                )}
                            </Form.List>
                        </Col>

                        <Col sm={24} xxl={12}>
                            <Title level={3}>Add your storage bins and augur sequence</Title>
                            <Form.List name={'storageBinSettings'}>
                                {(fields, { add, remove}) => (
                                    <>
                                        <Flex  gap={24}>
                                            <p>
                                                Add or remove a storage bin
                                            </p>

                                            <Button
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => {
                                                if (fields.length >= 1) {
                                                    remove(fields.length - 1);
                                                }
                                            }}
                                            />
                                            <Form.Item>
                                                <Button icon={<PlusCircleOutlined />} onClick={() => add()} />
                                            </Form.Item>
                                        </Flex>
                                        <br/>
                                        {fields.map( (field, index) => {
                                            return(
                                                <Flex align="middle" justify="center" wrap gap={24}>
                                                    <Form.Item name={[index, 'number']} hidden/>
                                                    <Form.Item
                                                    label={`Storage bin ${index + 1} name`}
                                                    name={[index, "name"]}
                                                    >
                                                        <Input/>
                                                    </Form.Item>

                                                    <Form.Item
                                                    name={[index, "transferAugurRotationString"]}
                                                    label="Augur sequence"
                                                    rules={[
                                                        { required: true, message: 'Please input a sequence of numbers!' },
                                                        {
                                                        pattern: /^(\d+\s*,\s*)*\d+$/,
                                                        message: 'Please enter a valid comma-separated sequence of numbers!',
                                                        },
                                                    ]}
                                                    >
                                                        <Input placeholder="e.g., 1, 2, 3, 4" />
                                                    </Form.Item>
                                                    <br/>
                                                </Flex>
                                            )
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col sm={24} xxl={8}>
                            <Title level={4}>Update fan type</Title>
                            <Form.Item
                            name={"fanTypeId"}
                            label={"Update your fan type"}
                            >
                                <Select style={{ width: 200 }}>
                                    {fanItems}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col sm={24} xxl={8}>
                            <Title level={4}>Update machine type</Title>
                            <Form.Item
                            name={"machineType"}
                            label={"Update your Machine type"}
                            >
                                <Radio.Group>
                                    <Radio value={PremierPlusMachineType.DriFlo}>DRI-Flo</Radio>
                                    <Radio value={PremierPlusMachineType.CenterVertical}>Center Vertical</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            values?.machineType === PremierPlusMachineType.DriFlo && 
                            <Col sm={24} xxl={8}>
                                <Title level={4}>Update machine delay</Title>
                                <Form.Item
                                name={"driFloMachineDelaySeconds"}
                                label={"Update your machine delay"}
                                // labelCol={{ span={24 }}}
                                >
                                    <InputNumber style={{ width:"200px" }} width={200}/>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    <br/>
                    <Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                                Submit
                            </Button>
                        </Form.Item>
                    </Row>
                </Spin>

            </Form>
        </>
    )
}

export default SettingsPanel;