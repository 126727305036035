import { Button, Card, Col, Divider, Form, InputNumber, Row, Typography } from "antd";
import React, { useState, useEffect } from "react";

const { Text } = Typography;

const FanControl = () => {

  return (
    <Card
      style={{
        margin: "0 auto",
        maxWidth: "100%",
        minWidth: "250px",
        width: "80%",
      }}
      title="Fan Control"
    >
      {/* Buttons and Labels */}
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={6} >
          <Button style={{ backgroundColor: "green", width: "100%" }}>OK</Button>
        </Col>
        <Col xs={24} sm={12} md={6} >
          <Text>Control Panel Switch</Text>
        </Col>

        <Col xs={24} sm={12} md={6} >
          <Button style={{ backgroundColor: "green", width: "100%" }}>OK</Button>
        </Col>
        <Col xs={24} sm={12} md={6} >
          <Text>Drying fan switch and high limits</Text>
        </Col>

        {/* Time Inputs */}
        <Col span={24}>
          <Text strong>Time to Run</Text>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label="Hours">
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label="Minutes">
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
        </Col>

        <Divider/>

        {/* Timer Section */}
        <Col xs={24}>
          <Button style={{ width: "100%" }}>Timer Stopped</Button>
        </Col>

        <Col xs={24}>
          <Text strong>Fan(s) will shutdown at</Text>
        </Col>

        <br/>

        <Col xs={24} sm={12}>
          <Button style={{ width: "100%" }}>9:00 AM</Button>
        </Col>
        <Col xs={24} sm={12}>
          <Button style={{ width: "100%" }}>12/16/2024</Button>
        </Col>

        {/* Start/Stop Buttons */}
        <Col span={24} style={{ textAlign: "center", marginTop: "16px" }}>
          <Row justify="center" gutter={[16, 16]}>
            <Col xs={12} sm={8}>
              <Button type="primary" style={{ width: "100%" }}>
                Start Timer
              </Button>
            </Col>
            <Col xs={12} sm={8}>
              <Button type="default" style={{ width: "100%" }}>
                Stop Timer
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default FanControl;
