import * as React from 'react';
import { Input, InputNumber, Switch, Form, Layout, Button, Dropdown, Menu, notification, Col, Row, Typography, message, Popconfirm, FormInstance} from 'antd';
import GrowerDTO from 'src/models/GrowerDTO';
import EnterpriseApiService from 'src/api/EnterpriseApiService';
import { Link } from 'react-router-dom';
import Routes from 'src/consts/Routes';
import RoleUtil from 'src/utils/RoleUtil';
import Role from 'src/consts/Role';
import { Select }  from 'antd';
import { LabeledValue } from "antd/lib/select";
import { SearchOptionRender, usePremierUserSearchQuery } from '../users/ExternalUserEdit';

interface State {
    chosenGrowerId: number | null;
    chosenGrowerName: string;
    currentGrower: GrowerDTO | null;
    growerIdList: [number, string][];
}

const { Option } = Select;
const { Text } = Typography;

class GrowerAdministration extends React.Component<{ grower?: [number, string], isSettings: boolean }, State> {

    private readonly _formRef = React.createRef<FormInstance>();
    constructor(props: { grower?: [number, string], isSettings: boolean }) {
        super(props);

        this.state = {
            chosenGrowerId: this.props.grower ? this.props.grower[0] : null,
            chosenGrowerName: this.props.grower ? this.props.grower[1] : '',
            currentGrower: null,
            growerIdList: [],
        };
    }

    componentDidMount() {
        if (RoleUtil.currentUserIsAdmin()) {
            this.getData();
        } else {
            if (this.state.chosenGrowerId) {
                this.handleGrowerIdClicked(this.state.chosenGrowerId);
            }
        }
    }

    delete = () => {
        EnterpriseApiService.removeGrower(this.state.chosenGrowerId!).then((success: boolean) => {
            if (success) {
                message.success('Success!');
                this.setState({
                    chosenGrowerId: null,
                    chosenGrowerName: '',
                    currentGrower: null
                });
            }
        });
    }

    render() {

        const isAdmin = RoleUtil.currentUserIsAdmin();
        const layout = {
            labelCol: { xs: 8, sm: 8, md: 6, lg: 6, xl: 4 },
            wrapperCol: { xs: 16, sm: 16, md: 18, lg: 18, xl: 20 },
        };
        return (
            <Layout.Content className="GrowerAdministration">
                <Row justify="space-between">
                    {isAdmin &&
                        <Col>
                            <Text>Organization:</Text>
                            <Select
                            style={{ width: "100%", maxHeight: "300px", overflow: "auto" }}
                            placeholder="Select Organization"
                            showSearch
                            optionFilterProp="children" // This is important for search to work
                            onSelect={(val) => {
                                this.handleGrowerIdClicked(val)
                            }}
                            >
                                {RoleUtil.currentUserIsAdmin() &&
                                    <Option value={null}>Create New Organization</Option>
                                }
                                {this.state?.growerIdList.map((pair) => (
                                    <Option key={pair[0]} value={pair[0]}>
                                        {pair[1]}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    }
                    <Col>
                        {this.state.chosenGrowerId && this.state.chosenGrowerName &&
                            <Dropdown overlay={this.binIdDropdownValues} placement="bottomLeft">
                                <Button style={{ marginBottom: '16px' }} type="primary">Go to {this.state.chosenGrowerName} Bins</Button>
                            </Dropdown>
                        }
                    </Col>
                </Row>
                {this.state.currentGrower &&
                    <Form onFinish={this.onFinish} initialValues={this.state.currentGrower} ref={this._formRef}>
                        <Form.Item
                            {...layout}
                            label="Organization Name"
                            name="growerName"
                            rules={[{ required: true, message: 'Enter the name of the Grower' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                        {...layout}
                        name={"isActive"}
                        label={"Is Active"}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item {...layout} label="Notes" name="notes">
                            <Input />
                    </Form.Item>

                    <Form.Item
                            {...layout}
                            label="Shivvers Bins"
                            name="externalBinIds"
                            >
                            <Search />
                        </Form.Item>
                    <Row gutter={16}>
                    {
                        !!this.state.chosenGrowerId && <Col>
                            <Form.Item>
                                <Popconfirm title="Delete this Organization?" okText="Delete" onConfirm={this.delete}>
                                    <Button type="primary" danger>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
                        </Col>
                        }
                        <Col>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                            </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            
                        </Col>
                    </Row>
                    </Form>
                }
            </Layout.Content>
        );
    }

    private onFinish = (values: any) => {
        let formGrower = {
            growerID: this.state.chosenGrowerId ? this.state.chosenGrowerId : 0,
            growerName: values.growerName,
            isActive: values.isActive,
            notes: values.notes,
            externalBinIds: values.externalBinIds,
        } as GrowerDTO;
        if (!this.state.chosenGrowerId) {
            EnterpriseApiService.addGrower(formGrower).then(() => {
                notification.success({
                    message: 'New Organization Added Succesfully'
                });
                this.getData();
                this.setState({
                    chosenGrowerId: null,
                    chosenGrowerName: '',
                    currentGrower: null
                });
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        } else {
            EnterpriseApiService.updateGrower(formGrower).then(() => {
                notification.success({
                    message: 'Organization Updated Succesfully'
                });
                this.getData();
                if (RoleUtil.currentUserIsAdmin()) {
                    this.setState({
                        chosenGrowerId: null,
                        chosenGrowerName: '',
                        currentGrower: null
                    });
                }
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }
    }

    private getData() {
        EnterpriseApiService.getGrowerIDs(false).then((growerIDs) => {
            let GrowerNameIDPairs: [number, string][] = growerIDs.map((grower) => [grower.growerID, grower.growerName ? grower.growerName : 'error: no name']);
            this.setState({
                growerIdList: GrowerNameIDPairs
            });
        }).catch(error => {
            notification.error({
                message: error.message,
                description: error.description
            });
        });
    }
    private handleGrowerIdClicked = async (id: number) => {
        if (id) {
            EnterpriseApiService.getGrower(id).then(grower => {
                this.setState({
                    chosenGrowerId: grower.growerID,
                    chosenGrowerName: grower.growerName ?? "",
                    currentGrower: grower
                });
                this._formRef.current?.resetFields();
            }).catch(error => {
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        } else {
            await this.setState({
                chosenGrowerId: null,
                chosenGrowerName: 'New Organization',
                currentGrower: GrowerDTO.create()
            });
            this._formRef.current?.resetFields();
        }
    }

    private binIdDropdownValues = () => {
        return (
            <Menu>
                {this.state?.currentGrower?.bins?.map((binInfo, index) => (
                    <Menu.Item key={index} style={{ margin: '0' }}>
                        <Link to={{
                            pathname: Routes.generate((this.props.isSettings ? Routes.SETTINGS : Routes.ADMIN) + '/2'),
                            state: { passedBin: binInfo, passedGrower: [this.state.chosenGrowerId, this.state.chosenGrowerName] }
                        }}>
                            <div>
                                {binInfo.name}
                            </div>
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        );
    }

}

// requuirements for a custom form control https://ant.design/components/form#form-demo-customized-form-controls
interface SearchProps {
    id?: string;
    value?: number[];
    onChange?: (value: number[]) => void;
}
export const Search = (props: SearchProps) => {
    const query = usePremierUserSearchQuery({onlyCustomers: true});

    const options = query.data?.map(u => {
        return { ...u, value: u.externalId, label: `${u.firstName} ${u.lastName}` }
    }) ?? [];
    return <Select {...props} mode="multiple" placeholder="Search by last, first or account" showSearch filterOption={(input, option) => {
        return (option?.label?.toUpperCase().includes(input.toUpperCase())
            || option?.account === (input)) ?? false;

    }} style={{ width: "400px" }} loading={query.isFetching} options={options} optionRender={(option) => {
        return <SearchOptionRender accountName={option.label} accountIp={option.data.account} />
    }} />
}

export default GrowerAdministration;
