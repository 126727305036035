import { useQuery } from "@tanstack/react-query";
import PremierPlusBinDTO from "src/models/PremierPlusBinDTO";
import BinApiService from "src/api/BinApiService";
import { queryClient } from "src";
import { QueryTypes } from './shared';

export const useGetPremierPlusBinDto = (binId: number, options: QueryTypes = {}) => {
    
    const getPremierPlusBinDtoQueryKey = "getPremierPlusBinDto";

    const getPremierPlusBinDtoQuery = useQuery<PremierPlusBinDTO>({
        queryKey: [getPremierPlusBinDtoQueryKey],
        queryFn: async (q) => {
            return await BinApiService.getPremierPlusBinDetailFromAzure(binId, 0, q.signal);
        },
        enabled: options.enabled,
        refetchOnWindowFocus: options.refetchOnWindowFocus
    })

    return { getPremierPlusBinDtoQueryKey, getPremierPlusBinDtoQuery }
}