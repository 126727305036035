// Constants TypeScript files should be AUTO-GENERATED by the Typewriter Visual Studio plugin. Do not modify this file.
enum FanTypeId {
    BlueFlameAxial = 1,
    BlueFlameWithTurbo = 2,
    HP10CFan27In = 3,
    HP15CFan27In = 4,
    HP20CFan30In = 5,
    AirMax30HP30In = 6,
    AirMax40HP33In = 7,
    AirMax50HP33In = 8,
}
export default FanTypeId;
