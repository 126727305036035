import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Col, DatePicker, Flex, Layout, Modal, Row, Tabs, TabsProps, Typography } from "antd";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Routes from "src/consts/Routes";
import { usePermissions } from "src/pages/features/usePermissions";
// import { useBinInfoContext } from "src/queries/BinInfoContext";
import { RedoOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { PremierPlusTapeStrip } from "./PremierPlusTapeStrip";
import PremierPlusAlertTable from "./PremierPlusAlertTable";
import Overview from "./Overview";
import { ReadingOnOffDisplay } from "./ReadingOnOffDisplay";
import { DryerSettingsCard } from "./DryerSettingsCard";
import { SettingsEdit } from "./SettingsEdit";
import { Charts } from "./Charts";
import ControlPanel from "./ControlPanel";
import SettingsPanel from "./SettingsPanel";
import { useBinInfoContext } from "src/queries/BinInfoContext";

const PremierPlusDashboard = () => {

    const [activeTabKey, setActiveTabKey] = useState("1");
    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
          key: '1',
          label: 'Dashboard',
          children: <ControlPanel />
        },
        {
            key: '2',
            label: 'System Configuration',
            children: <SettingsPanel />
        }
      ];
      
    return (
        <Layout.Content className="dashboard">
            <Tabs activeKey={activeTabKey} items={items} onChange={handleTabChange}/>
        </Layout.Content>
    );
};

export default PremierPlusDashboard;
