import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Col, DatePicker, Flex, Layout, Modal, Row, Tabs, Typography } from "antd";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import Routes from "src/consts/Routes";
import { usePermissions } from "src/pages/features/usePermissions";
// import { useBinInfoContext } from "src/queries/BinInfoContext";
import { RedoOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import { PremierPlusTapeStrip } from "./PremierPlusTapeStrip";
import PremierPlusAlertTable from "./PremierPlusAlertTable";
import Overview from "./Overview";
import { ReadingOnOffDisplay } from "./ReadingOnOffDisplay";
import { DryerSettingsCard } from "./DryerSettingsCard";
import { SettingsEdit } from "./SettingsEdit";
import { Charts } from "./Charts";
import FanControl from "./FanControl";
import MoveGrainControl from "./MoveGrainControl";

const ControlPanel = () => {

    const history = useHistory();
    const location = useLocation();
    const permissions = usePermissions();
    const binInfo = {
        name: "Test_PremierPlus_Physical_1"
    };
    const lastTapestripTime = dayjs("2024-12-09T15:30:00");

    const [activeTabKey, setActiveTabKey] = useState("1");
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showTapestripLog, setShowTapestripLog] = useState(false);

    const [tapestripHistoryModalRange, setTapestripHistoryModalRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

    return (
        <>
            {/* <Row justify={"space-between"} align="middle">
                <Col>
                    <PageHeader
                        style={{ width: "100%", paddingRight: 16, paddingLeft: 10 }}
                        onBack={() => {
                            // key unique to the location, only present if we came to this page from another first using React Router. ex. clicking details in BinDetails.
                            // https://reactrouter.com/en/main/start/concepts#locations
                            const hasHistory = !(location?.key == null);
                            if (!hasHistory) {
                                // going to the homepage is the best we can do currently (no knowledge of prior history or upper route/component to go back to)
                                history.push(Routes.HOME_ROUTE);
                            } else {
                                history.goBack();
                            }
                        }}
                        title={binInfo.name}
                        tags={[]}
                    />
                </Col>
                <Col>
                    <Typography.Text style={{ textAlign: "center", verticalAlign: "center" }} type="secondary">{`Last Tape Reading: ${
                        lastTapestripTime != null ? lastTapestripTime?.format("MM/DD/YYYY h:mm a") : "----"
                    }`}</Typography.Text>
                </Col>
                <Col style={{ marginLeft: "auto" }}>
                    <Button size="small" icon={<RedoOutlined />} type="primary" />
                </Col>
            </Row>
            <Modal
                title={
                    <>
                        <Flex gap="large" align="center" wrap="wrap">
                            <Typography.Text>Tapestrip</Typography.Text>
                            <DatePicker.RangePicker
                                allowClear
                                onChange={(dates, dateStrings) => {
                                    const userTimezone = dayjs.tz.guess();

                                    let startOf = null;
                                    let endOf = null;
                                    if (dates?.[0] != null) {
                                        const convertedStart = dayjs.tz(dates[0], userTimezone);
                                        startOf = dayjs.tz(convertedStart?.startOf?.("day"));
                                    }
                                    if (dates?.[1] != null) {
                                        const convertedEnd = dayjs.tz(dates[1], userTimezone);
                                        endOf = convertedEnd?.endOf?.("day");
                                    }

                                    setTapestripHistoryModalRange([startOf, endOf]);
                                }}
                                showTime={false}
                            />
                        </Flex>
                    </>
                }
                styles={{ body: { overflowY: "auto", maxHeight: "calc(100vh - 200px)" } }}
                width={"60%"}
                footer={null}
                open={showTapestripLog}
                onOk={() => {
                    setShowTapestripLog(false);
                }}
                onCancel={() => {
                    setShowTapestripLog(false);
                }}
            >
                <PremierPlusTapeStrip range={tapestripHistoryModalRange} />
            </Modal>

            <Modal
                title="Alerts"
                styles={{ body: { overflowY: "auto", maxHeight: "calc(100vh - 172px)" } }}
                width={"85%"}
                footer={null}
                open={showAlertModal}
                onOk={() => {
                    setShowAlertModal(false);
                }}
                onCancel={() => {
                    setShowAlertModal(false);
                }}
            >
                <PremierPlusAlertTable />
            </Modal>
            <Row justify={"space-around"} gutter={[16, 16]}>
                <Col
                    xs={24}
                    xl={8}
                    style={{
                        justifyContent: "center"
                    }}
                >
                    <Flex flex="wrap" justify={"center"}>
                        <Card
                            title="Last Tape Reading"
                            extra={
                                <Flex gap="small">
                                    <Button
                                        onClick={() => {
                                            setShowTapestripLog(true);
                                        }}
                                    >
                                        Tapestrip
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setShowAlertModal(true);
                                        }}
                                    >
                                        Alerts
                                    </Button>
                                </Flex>
                            }
                            style={{
                                width: "100%"
                            }}
                            styles={{
                                body: {
                                    padding: 0
                                }
                            }}
                        >
                            <Overview layout="horizontal" showLastTapeReadingDate={true} />
                        </Card>
                    </Flex>
                </Col>
                <Col xs={24} xl={8}>
                    <Flex justify={"center"} flex="wrap">
                        <ReadingOnOffDisplay />
                    </Flex>
                </Col>
                <Col xs={24} xl={8}>
                    <Flex justify={"center"}>
                        <DryerSettingsCard />
                    </Flex>
                </Col>
            </Row> */}

            {/* {permissions.canWrite && <Row>
                <Col span={24}>
                    <SettingsEdit />
                </Col>
            </Row>} */}
            
            <br/>
            
            <FanControl />

            <br/>
            
            <MoveGrainControl />

            <br/>

            {/* <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Charts />
                </Col>
            </Row> */}
        </>
    );
};

export default ControlPanel;