import { Button, message, Row, Skeleton } from "antd";
import { StatusCodes } from "http-status-codes/build/cjs/status-codes";
import React, { useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetBinByIDQuery } from "src/queries/useGetBinByIDQuery";
import { deviceQueryKeys } from "../HomeScreen/BinDetails";
import BinStatsPage, { HistoryState } from "./BinStatsPage";
import { queryClient } from "src";
import { connect } from "react-redux";
import { GrowerSlim, UserSessionActions } from "src/redux/actions/UserSessionActions";
import { BinInfoContext } from "src/queries/BinInfoContext";
import AutomationType from "src/consts/AutomationType";
import PremierPlusDashboard from "src/pages/features/PremierPlus/PremierPlusDashboard";

interface Props {
    growerChangeAction: (grower: GrowerSlim) => void;
}


export const invalidateBinState = (deviceId: string): NodeJS.Timeout => {
    const timeoutId = setTimeout(async () => {
        console.log("invaliding binState at", Date());
        await queryClient.invalidateQueries(deviceQueryKeys.stateFromDevice(deviceId));
        message.info('Data Refreshing...', 2);
    }, 8000);
    return timeoutId;
};



export const strictParseInt = (maybeInt: string | null | undefined, radix: number): number => {
    if (maybeInt == null) {
        return NaN;
    }
    const initial = parseInt(maybeInt, radix);
    if (initial.toString() !== maybeInt) {
        return NaN;
    }
    return initial;
}

const BinStatsPageParent = (props: Props) => {
    const params = useParams<any>();
    const history = useHistory<HistoryState>();
    const binIdParam = params.binId?.replaceAll("-", ".");
    console.log("params", params);
    console.log("translated param", binIdParam);

    const binId = strictParseInt(binIdParam, 10);
    // Nan, Ids < 0, non-numeric, all evaluate to false
    const validbinIdInUrl = binId >= 0;
    console.log("parsed binId param", {binId, valid: validbinIdInUrl});


    if (!validbinIdInUrl) {
        return <>
            <Row justify="center" >
                <h1><b>{binIdParam}</b> is not a valid bin identifer</h1>
            </Row>
            <Row justify="center">
                <Button onClick={() => {
                    history.push("/");
                }}>Go Home</Button>
            </Row>
        </>

    }

    let binInfoQuery = useGetBinByIDQuery(binId, {enabled: validbinIdInUrl});

    useEffect(() => {
        const growerId = binInfoQuery.data?.growerId;
        if (!(growerId)) {
            return;
        }
        const bininfo = binInfoQuery.data;
        const grower: GrowerSlim = {
            externalId: null,
            growerId: bininfo?.growerId!,
            growerName: bininfo?.growerName!,
            isExternal: false,
        };
        props.growerChangeAction(grower);
    }, [binInfoQuery.data?.growerId]);

    const unauthorizedBin = binInfoQuery.error?.status === StatusCodes.FORBIDDEN;
    const binNotFound = binInfoQuery.error?.status === StatusCodes.NOT_FOUND;
    const notFoundOrUnauthorized = unauthorizedBin || binNotFound;

    if (notFoundOrUnauthorized) {
        console.log("Redirecting to homepage due to unauthorized or notfound bin");
        return <Redirect to="/" />;
    }


    if (binInfoQuery.data) {
        return <>
        <BinInfoContext.Provider value={binInfoQuery.data}>
            {binInfoQuery.data.automationType === AutomationType.PremierPlus && <PremierPlusDashboard />}
            {binInfoQuery.data.automationType !== AutomationType.PremierPlus && <BinStatsPage binInfo={binInfoQuery.data} />}
        </BinInfoContext.Provider>
        </>;
    }

    if (binInfoQuery.isLoading) {
        return <>
            <Skeleton />
        </>
    }

    if (binInfoQuery.isError) {
        return <>
            <h2>An unexpected server error occurred loading the bin</h2>
            <Link to="/">Back to Dashboard</Link>
            <h2>Contact Support if this perists.</h2>
        </>;
    }

    return <></>

}




function mapDispatchToProps(dispatch: any) {
    return {
        growerChangeAction: (grower: GrowerSlim) => dispatch(UserSessionActions.changeGrower(grower)),
    };
}

export default connect(null, mapDispatchToProps)(BinStatsPageParent);
