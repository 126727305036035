import { Button, Card, Col, Divider, Form, Row, Select, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const MoveGrainControl = () => {
  return (
    <Card
      style={{
        margin: "0 auto",
        maxWidth: "100%",
        minWidth: "250px",
        width: "80%",
      }}
      title="Move Grain Control"
    >
      <Row gutter={[16, 16]} justify="center">
        {/* Storage Bin Select */}
        <Col span={24}>
          <Form.Item label="Storage Bin Name" labelCol={{ xs:24, sm:8 }}>
            <Select style={{ width: "100%", maxWidth: "400px" }} />
          </Form.Item>
        </Col>

        {/* Shut Off Section */}
        <Col xs={24} sm={8}>
          <Text>Shut off with LGSO?</Text>
        </Col>

        <Col xs={24} sm={12}>
          <Button style={{ width: "100%" }}>No</Button>
        </Col>

        <Col xs={24} sm={8}>
          <Text>Low Grain Shut Off</Text>
        </Col>

        <Col xs={24} sm={12}>
          <Button style={{ width: "100%" }}>OK</Button>
        </Col>


        <Divider/>
        
        {/* Time Inputs */}
        <Col span={24}>
          <Text strong>Time to Run</Text>
        </Col>

        <Col xs={24} sm={6}/>
        <Col xs={24} sm={6}>
          <Text>Hours</Text>
        </Col>
        <Col xs={24} sm={6}>
          <Text>Minutes</Text>
        </Col>
        <Col xs={24} sm={6}>
          <Text>Seconds</Text>
        </Col>

        <Col xs={8} sm={6}>
            <Button style={{ backgroundColor: "navy", width: "100%" }} />
        </Col>
        <Col xs={8} sm={6}>
          <Button style={{ backgroundColor: "navy", width: "100%" }} />
        </Col>
        <Col xs={8} sm={6}>
          <Button style={{ backgroundColor: "navy", width: "100%" }} />
        </Col>

        {/* Total Time Ran */}
        <Col span={24}>
          <Text strong>Total Time Ran</Text>
        </Col>

        <Col xs={8} sm={6}>
          <Button style={{ backgroundColor: "grey", width: "100%" }} />
        </Col>
        <Col xs={8} sm={6}>
          <Button style={{ backgroundColor: "grey", width: "100%" }} />
        </Col>
        <Col xs={8} sm={6}>
          <Button style={{ backgroundColor: "grey", width: "100%" }} />
        </Col>

        {/* Switches Section */}
        <Col span={24}>
          <Text strong>Switches Auto/Off</Text>
        </Col>
        {["Auto", "OFF", "ON", "OFF", "OFF", "OFF"].map((label, index) => (
          <Col xs={12} sm={4} key={`switch-${index}`}>
            <Button style={{ width: "100%" }}>{label}</Button>
          </Col>
        ))}

        {/* Machine Augur Status */}
        <Col span={24}>
          <Text strong>Machine Augur Status</Text>
        </Col>
        {["Auto", "OFF", "ON", "OFF", "OFF", "OFF"].map((label, index) => (
          <Col xs={12} sm={4} key={`status-${index}`}>
            <Button style={{ width: "100%" }}>{label}</Button>
          </Col>
        ))}

        {/* Start/Stop Buttons */}
        <Col span={12} style={{ textAlign: "center", marginTop: "16px" }}>
          <Button type="primary" style={{ width: "100%" }}>
            Start Timer
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: "center", marginTop: "16px" }}>
          <Button type="default" style={{ width: "100%" }}>
            Stop Timer
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default MoveGrainControl;
