import * as React from 'react';
import AccountApiService from 'src/api/AccountApiService';
import ChangePasswordForm from './ChangePasswordForm';
import ChangePasswordRequestModel from 'src/models/ChangePasswordRequestModel';
import CurrentUser from 'src/utils/CurrentUser';
// import FormattedAddress from './shared/FormattedAddress';
// import FormattedPhone from './shared/FormattedPhone';
import UserApiService from 'src/api/UserApiService';
import { BreadcrumbsItem } from './shared/GlobalBreadcrumb';
import {
    Button,
    Card,
    Col,
    notification,
    Row,
    Spin,
    Descriptions,
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

import Routes from 'src/consts/Routes';
import FormattedPhone from './shared/FormattedPhone';
import FormattedAddress from './shared/FormattedAddress';
import GrowerDTO from 'src/models/GrowerDTO';
import UserDetailDTO from 'src/models/UserDetailDTO';
import HistoryUtil from 'src/utils/HistoryUtil';
import FormItem from 'antd/es/form/FormItem/index';
const { Item } = Descriptions;

interface ProfileProps {
}

interface ProfileState {
    loading: boolean;
    user: UserDetailDTO | null;
    showChangePassword: boolean;
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    constructor(props: ProfileProps) {
        super(props);
        this.state = {
            user: null,
            showChangePassword: false,
            loading: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        let { user, loading, showChangePassword } = this.state;
        let layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },

        };
        let columns = { xs: 24, sm: 24, md: 12, lg: 12, xl: 8, xxl: 8 };

        return (
            <div className="content-background" style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
                <div className="content-container">
                    <BreadcrumbsItem name="user_profile" to={Routes.generate(Routes.PROFILE)}>
                        User Profile
                    </BreadcrumbsItem>

                    <Spin spinning={loading}>
                        <PageHeader title="Profile"   onBack={() => { HistoryUtil.goBack(); }}/>
                        <Row>
                            <Descriptions style={{ fontWeight: 'bold' }}  >
                                <Item label="First Name" >
                                    {user?.firstName}
                                </Item>
                                <Item label="Last Name">
                                    {user?.lastName}
                                </Item>
                                <Item label="User Active">
                                    {`${user?.isActive}`}
                                </Item>
                                <Item label="Phone">
                                    <FormattedPhone phone={user?.phone} />
                                </Item>
                                <Item label="Email">
                                    {user?.email}
                                </Item>
                                <Item label="Address" {...layout}>
                                    <FormattedAddress {...user} />
                                </Item>
                            </Descriptions>
                        </Row>
                        <Row>
                            {(user?.growers || []).map((grower: GrowerDTO, i: number) => {
                                return (<Card key={i} style={{ marginBottom: 16 }}>
                                    <Descriptions>
                                        <Item label="Organization Name">{grower.growerName}</Item>
                                        <Item label="Notes">{grower.notes}</Item>
                                    </Descriptions>
                                </Card>);
                            })}
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} style={{ marginTop: 8 }}>
                                <span style={{ fontStyle: 'italic', fontSize: '0.9em', color: '#666' }}>
                                    * If any information here is incorrect please contact an administrator.
                                     </span>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col {...columns} style={{ marginTop: 12 }}>
                                <FormItem label="Password" {...layout}>
                                    <Button onClick={this.onShowPasswordForm}>{!showChangePassword ? 'Change Password' : 'Hide'}</Button>
                                </FormItem>
                            </Col>
                        </Row>
                        {showChangePassword ? (
                            <Row justify="space-between" style={{ marginTop: 24 }}>
                                <Col className="flex-grow" xxl={0} xl={0} lg={0} />
                                <Col xxl={8} xl={12} lg={12} md={16} sm={20} xs={24}>
                                    <Card>
                                        <ChangePasswordForm
                                            loading={loading}
                                            onChangePassword={this.onChangePassword} />
                                    </Card>
                                </Col>
                                <Col className="flex-grow" />
                            </Row>
                        ) : null}
                    </Spin>

                </div>
            </div>
        );
    }

    onShowPasswordForm = () => {
        this.setState({ showChangePassword: !this.state.showChangePassword });
    }

    onChangePassword = (values: ChangePasswordRequestModel) => {
        this.setState({ loading: true });

        AccountApiService.changePassword(values).then(() => {
            this.setState({
                loading: false,
                showChangePassword: false
            });
            notification.success({
                message: 'Successfully changed password'
            });
        }).catch((error: any) => {
            this.setState({ loading: false });
            notification.error({
                message: error.message,
                description: error.description
            });
        });
    }

    fetchData = () => {
        this.setState({ loading: true });
        let userId = CurrentUser.Get()?.userId;
        if (userId) {
            UserApiService.getUser(userId).then(user => {
                this.setState({
                    user,
                    loading: false
                });
            }).catch(error => {
                this.setState({
                    loading: false
                });
                notification.error({
                    message: error.message,
                    description: error.description
                });
            });
        }
    }
}

export default Profile;
